import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from '../Pages/Home';
import AerApa from '../Pages/AerApa';
import SolApa from '../Pages/SolApa';
import ApaApa from '../Pages/ApaApa';
import ApaMenajera from '../Pages/ApaMenajera';
import Contacte from '../Pages/Contacte';

import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './img/logo.webp'

function BasicExample() {
    return (
        <Router>
            <Navbar collapseOnSelect expand="lg" className="bg-my text-center fixed-top">
                <Container  >
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            height="36"
                            width="150"
                            className="d-inline-block align-center"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto mlink m-auto">
                            <Nav.Link eventKey='1' class="m-auto px-4 my-3" as={Link} to="/">Home</Nav.Link>
                            {/* <div Class="m1 m-auto p-2">Pompe de caldura:</div> */}
                            <NavDropdown title="Pompe de caldura:" id="basic-nav-dropdown" className="me-auto mlink m-auto">
                                <NavDropdown.Item eventKey='2' as={Link} to="/AerApa">tip Aer-Apa</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='3' as={Link} to="/SolApa">tip Sol-Apa</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='4' as={Link} to="/ApaApa">tip Apa-Apa</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='5' as={Link} to="/ApaMenajera">pentru Apa Menajera</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link eventKey='6' class="mp m-auto px-4 my-3" as={Link} to="/Contacte">Contacte</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className='mlink'>
                        <h5><a href="tel:+37379939333">(+373) 799 39 333</a></h5>
                        <h5><a href="mailto:info@ochsner.md">info@ochsner.md</a></h5>
                    </div>
                </Container>
            </Navbar>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/AerApa' element={<AerApa />} />
                <Route path='/SolApa' element={<SolApa />} />
                <Route path='/ApaApa' element={<ApaApa />} />
                <Route path='/ApaMenajera' element={<ApaMenajera />} />
                <Route path='/Contacte' element={<Contacte />} />
            </Routes>
        </Router>

    );
}

export default BasicExample;