import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img from '../Components/img/aer-apa1.webp';
import img2 from '../Components/img/aer-apa2.webp';
import img3 from '../Components/img/aer-apa3.webp';
import img4 from '../Components/img/aer-apa4.webp';

class AerApa1 extends Component {
    render() {
        return (
            <Container>
                <div class='row mtext justify-content-center ptop m-3'>
                    <h2>Pompe de caldură tip Aer-Apă</h2>
                </div>
                <div class='row mtext justify-content-center '>
                    <div class='row-lg-10  text-center'>
                        <img
                            src={img}
                            height="400"
                            width="985"
                            class="img-fluid"
                            alt="Pompe Aer-Apa"
                        />
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5 '>
                <div class='col-lg-11 col-md-12'>
                    <h3>Rețeta OCHSNER pentru succes! Un principiu simplu, aplicat cu perfecțiune tehnică</h3>
                    <p>
                        Toate pompele de căldură cu sursă de aer funcționează în același mod. Un ventilator atrage aerul
                        exterior, care trece apoi printr-un schimbător de căldură, determinând evaporarea agentului
                        frigorific din pompa de căldură. În circuitul pompei de căldură, aceasta este apoi adusă la o
                        temperatură mai ridicată prin compresie într-un compresor alimentat electric. Energia este
                        transferată prin intermediul unui schimbător de căldură în apă care poate fi folosită pentru
                        încălzire și încălzire apei menajere. Principalele diferențe sunt în detalii: Tehnologia de
                        înaltă performanță OCHSNER furnizează energie suficientă pentru încălzire și apă menajeră chiar
                        și la temperaturi ale aerului exterior rece. Face acest lucru folosind agenți frigorifici cu un
                        punct de fierbere foarte scăzut. Mai presus de toate, componentele trebuie să fie toate perfect
                        potrivite și să funcționeze perfect împreună. Aceasta înseamnă că puteți conta pe pompele de
                        căldură cu sursă de aer OCHSNER pentru a funcționa cu eficiență maximă și consum minim de energie.
                        Aparatele de bază mai simple folosesc ventilatoare verticale pentru admisia aerului. Aparatele de
                        ultimă generație OCHSNER sunt echipate cu evaporatoare de banc cu ventilatoare de foarte înaltă
                        calitate, care sunt instalate orizontal. Acestea oferă o putere mai mare și o funcționare mai
                        silențioasă.
                    </p>
                </div>
                </div>
                <div class='row mtext justify-content-center m-3'>
                    <div class='col-md-4 col-sm-9'>
                        <img
                            src={img2}
                            height="400"
                            width="450"
                            class="img-fluid"
                            alt="Pompa Aer-Apa1"
                        />
                    </div>
                    <div class='col-md-4 col-sm-9 '>
                        <img
                            src={img3}
                            height="400"
                            width="450"
                            class="img-fluid"
                            alt="Pompa Aer-Apa2"
                        />
                    </div>
                    <div class='col-md-4 col-sm-9 '>
                        <img
                            src={img4}
                            height="400"
                            width="450"
                            class="img-fluid"
                            alt="Pompa Aer-Apa3"
                        />
                    </div>
                </div>
            </Container >
        );
    }
}

export default AerApa1;