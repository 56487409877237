import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img4 from '../Components/img/sol-apa4.webp';

class SolApa2 extends Component {
    render() {
        return (
            <Container>
                <div class='row mtext justify-content-center ptop'>
                    <div class='col-lg-4 col-md-4 text-center  my-auto '>
                        <img
                            src={img4}
                            height="506"
                            width="217"
                            class="img-fluid"
                            alt="device"
                        />
                    </div>
                    <div class='col-lg-7 col-md-8'>
                        <h3>Controlul temperaturii cu o pompă de căldură cu sursă de sol OCHSNER: răcire și încărcare rezervor termic</h3>
                        <p>
                            Pompa de căldură OCHSNER poate fi proiectată pentru a permite suplimentar
                            răcirea și controlul temperaturii vara. Există două procese de răcire din
                            care puteți alege. În răcirea pasivă, căldura este transferată de la sistemele
                            de încălzire de suprafață către sol prin intermediul sondelor geotermale.
                            Deoarece pământul este mai rece decât aerul din casă vara, cu temperaturi în
                            mod constant în jurul valorii de 10°C, temperatura camerei poate fi scăzută cu
                            câteva grade. Costurile cu energia sunt minime, deoarece este activată doar
                            pompa de circulație pentru agentul de transfer termic din sondele geotermale.
                            La răcirea activă, procesul pompei de căldură este inversat. În acest caz,
                            pompa de căldură folosește casa ca sursă de căldură și transferă energia termică
                            către exterior prin intermediul colectoarelor orizontale sau al sondelor geotermale.
                            Consumul de energie este ceva mai mare decât la versiunea pasivă, dar capacitatea de
                            răcire este semnificativ mai mare și mai ușor de controlat. Numai răcirea activă poate
                            fi utilizată cu colectoarele orizontale instalate în pământ.
                        </p>
                        <p>
                            Pompele de căldură OCHSNER asigură controlul temperaturii prin utilizarea
                            solului ca acumulator de căldură. Acest lucru face posibilă utilizarea
                            căldurii reziduale din casă pentru a îmbunătăți randamentul căldurii în
                            timpul iernii.
                        </p>
                    </div>

                </div>
                <div class='row mtext justify-content-center pt-5 pbottom'>
                    <div class='col-lg-11 col-md-12 m-auto'>
                        <h3>Întrebări despre pompele de căldură de la sol</h3>
                        <p>
                            <b>Ar putea o pompă de căldură la sol cu colectoare orizontale să-mi facă grădina mai răcoroasă?</b>
                        </p>
                        <p>
                            Răcirea grădinii cu semne vizibile pe plante se poate întâmpla doar
                            dacă suprafața de colectare este o dimensiune insuficientă. Pe lângă
                            datele cheie, partenerii sistemului OCHSNER prelevează mostre de sol
                            sau folosesc valori empirice din regiune ca parte a planificării și
                            dimensionării lor profesionale. Este recomandabil să includeți o
                            suprafață de colectare suplimentară pentru a permite orice creștere a
                            numărului de persoane sau o extindere ulterioară.
                        </p>
                        <p>
                            <b>Care sunt costurile de întreținere pentru o pompă de căldură Sol-Apă</b>
                        </p>
                        <p>
                            Sistemele cu sursă de sol OCHSNER aproape nu necesită întreținere.
                            Colectoarele orizontale și sondele geotermale sunt foarte durabile și
                            robuste și, în mod normal, nu necesită întreținere sau întreținere. Se
                            recomandă o inspecție anuală a întregului sistem.
                        </p>
                        <p>
                            <b>Ar putea îngheța colectoarele mele orizontale în ierni foarte reci?</b>
                        </p>
                        <p>
                            Cu o planificare corectă, colectorii geotermici sunt întotdeauna așezați
                            suficient de adânc în pământ într-un loc ferit de îngheț. Acest lucru
                            exclude orice îngheț.
                        </p>
                    </div>
                </div>
            </Container>
        );
    }
}

export default SolApa2;