import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../Components/img/apa1.webp';
import img2 from '../Components/img/apa2.webp';
import img3 from '../Components/img/apa3.webp';

class ApaMenajera1 extends Component {
    render() {
        return (
            <Container>
                <div class='row mtext justify-content-center pbottom ptop'>
                    <div class='col-lg-11 col-md-10 m-auto'>
                        <h2>Pompe pentru încălzirea apei menajere</h2>
                    </div>

                    <div class='col-lg-5  text-center pt-3'>
                        <img
                            src={img1}
                            height="400"
                            width="450"
                            class="img-fluid"
                            alt="Apa Menajera1"
                        />
                    </div>
                    <div class='col-lg-5  text-center pt-3'>
                        <img
                            src={img2}
                            height="400"
                            width="450"
                            class="img-fluid"
                            alt="Apa Menajera1"
                        />
                    </div>

                </div>
                <div class='row mtext justify-content-center '>
                    <div class='col-lg-11 col-md-11 m-auto'>
                        <p>
                            OCHSNER oferă o gamă largă de soluții de pompe de căldură pentru încălzirea apei menajere independent de
                            sistemul de încălzire. Pompele de căldură pentru apă caldă folosesc aerul din încăperile auxiliare, cum
                            ar fi subsolul, magazia sau spălătoria. Datorită temperaturilor relativ constante din aceste zone,
                            pompele de căldură cu apă caldă OCHSNER funcționează foarte eficient, oferind în mod fiabil temperaturi
                            apei de până la 65°C. În funcție de model, aparatele OCHSNER pot fi folosite și pentru dezumidificarea și
                            răcirea încăperii. OCHSNER oferă pompe de căldură pentru apă caldă ca aparate compacte cu rezervoare de apă
                            caldă menajeră integrate sau ca aparate split pentru rezervoarele externe de apă caldă menajeră.
                        </p>
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-4'>
                    <div class='col-lg-3 col-md-2 text-center mx-5 my-auto '>
                        <img
                            src={img3}
                            height="404"
                            width="695"
                            class="img-fluid"
                            alt="Apa Calda"
                        />
                    </div>
                    <div class='col-lg-6 col-md-7'>
                        <hr />
                        <p>
                            Sistemul de încălzire centrală este supradimensionat pentru singura funcție de a încălzi apă
                            menajeră vara. Pompele de căldură cu apă caldă OCHSNER pot fi ajustate exact la cererea reală.
                        </p>
                        <hr />
                    </div>
                </div>
                <div class='row mtext justify-content-center pbottom pt-5'>
                    <div class='col-lg-11 col-md-11'>
                        <p>
                            <h3>Apa calda menajera la tarif economic</h3>
                        </p>
                        <p>
                            Pompele de căldură cu apă caldă OCHSNER utilizează căldura reziduală și furnizează apă caldă foarte eficient
                            și cu un consum redus de energie, pe baza unui proces termodinamic. Prin urmare, tehnologia OCHSNER joacă un
                            rol important în protecția climei și reducerea costurilor energetice.
                        </p>
                    </div>
                </div>
            </Container>
        );
    }
}

export default ApaMenajera1;