import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Bottom extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mtext text-center bg-my p-3 mlink'>
                    <p>SC "Geotermal-AV" SRL</p>
                </div>
            </Container>
        );
    }
}

export default Bottom;