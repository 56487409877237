import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../Components/img/apa-apa1.webp';
import img2 from '../Components/img/apa-apa2.webp';

class ApaApa1 extends Component {
    render() {
        return (
            <Container>
                <div class='row mtext justify-content-center ptop m-3'>
                    <h2>Pompe de caldură tip Apă-Apă</h2>
                </div>
                <div class='row mtext justify-content-center '>
                    <div class='row-lg-10  text-center'>
                        <img
                            src={img1}
                            height="400"
                            width="985"
                            class="img-fluid"
                            alt="Pompe Apa-Apa"
                        />
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>
                    <div class='col-lg-11 col-md-12 m-auto'>
                        <h3>Sursă de căldură constantă pe tot parcursul anului</h3>
                        <p>
                            Cu temperaturi constante între 8 și 12°C pe tot parcursul anului, apa subterană este o sursă de căldură
                            care poate fi utilizată foarte eficient cu pompele de căldură apă/apă. Cu factori de performanță sezonieri
                            (SPF) de până la 6 în practica operațională și 4 în combinație cu radiatoare, pompele de căldură pentru
                            apă subterană OCHSNER sunt foarte profitabile și economice.
                        </p>
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>
                    <div class='col-lg-4 col-md-4 text-center mx-5 my-auto '>
                        <img
                            src={img2}
                            height="400"
                            width="450"
                            class="img-fluid"
                            alt="Apa-Apa"
                        />
                    </div>
                    <div class='col-lg-6 col-md-6'>
                        <hr />
                        <p>
                            Oriunde este disponibilă și utilizabilă apa subterană în conformitate cu legile privind apa,
                            pompele de căldură pentru apă subterană OCHSNER oferă o soluție extrem de atractivă și foarte
                            economică pentru încălzirea clădirilor și încălzirea Apei menajere. Pompele de căldură apă/apă
                            OCHSNER ating valori maxime de eficiență și dispun de o tehnologie de schimbător de căldură foarte
                            fiabilă pentru apele subterane.
                        </p>
                        <hr />
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>
                    <div class='col-lg-11 col-md-12 m-auto'>
                        <h3>Fântâni de alimentare și retur pentru circulația apelor subterane</h3>
                        <p>
                            Sunt necesare lucrări pregătitoare și foraje pentru a utiliza apele subterane ca sursă de căldură.
                            Se forează două fântâni: fântână de alimentare și fântână de absorbție sau retur. Apa subterană este
                            extrasă din fântână de alimentare și canalizată către pompa de căldură. Un schimbător de căldură
                            transferă energia agentului frigorific, care răcește ușor apa subterană. Sistemele OCHSNER asigură că
                            toată apa este apoi reintrodusă prin fântână de retur în stare curată, în conformitate cu cerințele legale.
                            Cele două găuri de foraj trebuie să fie amplasate la o distanță suficientă unul de celălalt pentru a se
                            asigura că fluxul mai cald și returul răcit nu se pot amesteca. Conducta de retur trebuie să se afle
                            întotdeauna în aval de punctul de evacuare, pe direcția curgerii apei subterane.
                        </p>
                    </div>
                </div>
            </Container >
        );
    }
}

export default ApaApa1;