import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import MapGoogle from './MapGoogle';
import img from '../Components/img/energy_nature.webp'

class Contacte extends Component {
    render() {
        return (
            <Container>
                {/* <div class=' mbp row mtext justify-content-center ptop'> */}
                <div class=' row mtext justify-content-center ptop pbottom'>
                    <div class='col-lg-6 col-md-none text-center d-none d-lg-block opacity-75'>
                        <img
                            src={img}
                            height="706"
                            width="500"
                            class="img-fluid"
                            alt="Energy from Nature"
                        />
                    </div>
                    <div class='col-lg-6 col-md-11 px-3'>
                        <div class='row border border-dark '>
                            <h4 class='m-4'>SC "Geotermal-AV" SRL</h4>
                            <h5 class='m-4'>telefon: (+373) 799 39 333</h5>
                            <h5 class='m-4'><a href='mailto:info@ochsner.md'>e-mail: info@ochsner.md</a></h5>
                            <h5 class='m-4'>Adresa: mun. Chisinau, str.Vasile Lupu 87/4</h5>
                        </div>


                        <div class='row pt-5 '>
                            <MapGoogle />
                        </div>
                    </div>
                </div>

                {/* </div> */}
            </Container>
        );
    }
}

export default Contacte;