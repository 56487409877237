import React, { Component } from 'react';
import AerApa1 from '../Components/AerApa1'
import AerApa2 from '../Components/AerApa2'
import Bottom from '../Components/Bottom'
import GoToTop from '../Components/GoToTop';

class AerApa extends Component {
    render() {
        return (
            <>
              <AerApa1 />
              <AerApa2 />
              <Bottom />
              <GoToTop />
            </>
        );
    }
}

export default AerApa;