import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Home3 extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mtext justify-content-center mbg pt-5 pbottom'>
                    <div class='col-lg-10 col-xxl-9 col-md-12'>
                        <h3 class='text-center'>Pompă de căldură: eficientă prin căldura din mediu</h3>
                        <p>Pompele de căldură OCHSNER utilizează surse de energie care sunt disponibile în cantități aproape
                            nelimitate:</p>
                        <ul>
                            <li>Căldură din aerul ambiant </li>
                            <li>Căldură de la sol</li>
                            <li>Căldură de la apele subterane</li>
                        </ul>
                        <p>Într-un proces termodinamic, pompele de căldură „pompează” temperatura sursei de căldură la un
                            nivel mai înalt și o fac utilizabilă pentru încălzire și apă caldă.</p>
                        <p><b>Doar un sfert din energia electrică de la priză Aproximativ trei sferturi din energia necesară
                            este extrasă din mediu într-un proces liber</b>, neutru din punct de vedere climatic, în timp ce
                            doar un sfert provine de la priză și este folosită pentru a funcționa pompa de căldură.
                            Cât de eficient funcționează o pompă de căldură depinde de sursa de căldură și – în mod
                            semnificativ – de tehnologie.</p>
                    </div>
                </div>
                <div class='row mtext justify-content-center mbg pbottom'>
                    <div class='col-lg-10 col-xxl-9 col-md-12'>
                        <h3 class='text-center'>De ce pompele de căldură OCHSNER</h3>
                        <p>La centrul de testare a pompelor de căldură Buchs din Elveția, pompele de căldură OCHSNER ating în
                            mod regulat valori de top pentru eficiență și performanță. În acest fel, OCHSNER stabilește
                            standarde pentru încălzire, răcire și apă caldă eficiente.</p>
                        <p>Peste 160.000 de sisteme instalate și sute de mii de clienți mulțumiți din gospodării private și
                            companii mari demonstrează fiabilitatea și siguranța operațională a pompelor de căldură OCHSNER.
                            Sunt de cea mai înaltă calitate și au o durată de viață de câteva decenii.</p>
                        <p>Tehnologia noastră foarte dezvoltată OCHSNER reduce nivelul de zgomot al pompelor de căldură sursă de aer la minimum:</p>
                        <ul>
                            <li>Evaporatoarele  OCHSNER cu ventilatoarele lor montate orizontal funcționează extrem de silențios.</li>
                            <li>Tehnologia invertorului permite reglarea vitezei la infinit variabil pentru a se potrivi cu cererea curentă de căldură.</li>
                        </ul>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Home3;