import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../Components/img/sol-apa1.webp';
import img2 from '../Components/img/sol-apa2.webp';
import img3 from '../Components/img/sol-apa3.webp';

class SolApa1 extends Component {
    render() {
        return (
            <Container>
                <div class='row mtext justify-content-center ptop m-3'>
                    <h2>Pompe de caldură tip Sol-Apă</h2>
                </div>
                <div class='row mtext justify-content-center '>
                    <div class='row-lg-10  text-center'>
                        <img
                            src={img1}
                            height="400"
                            width="985"
                            class="img-fluid"
                            alt="Pompe Aer-Apa"
                        />
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>
                    <div class='col-lg-11 col-md-12'>
                        <h3>Energie geotermală pentru casa ta</h3>
                        <p>
                            De zeci de ani, pompele de căldură OCHSNER au fost o soluție testată și
                            testată pentru alimentarea eficientă a clădirilor cu căldură ecologică de
                            la sol. Deoarece această sursă de energie este supusă doar fluctuațiilor
                            minime de temperatură de-a lungul anului, pompele de căldură OCHSNER obțin
                            valori de eficiență foarte bune, continuu și fiabil.
                        </p>
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>
                    <div class='col-lg-4 col-md-4 text-center mx-5 my-auto '>
                        <img
                            src={img2}
                            height="400"
                            width="450"
                            class="img-fluid"
                            alt="Sol-Apa"
                        />
                    </div>
                    <div class='col-lg-6 col-md-6 '>
                        <hr />
                        <p>
                            Pompele de căldură OCHSNER cu sursă de căldură din sol/apă oferă o gamă largă de
                            opțiuni pentru a face o utilizare extrem de eficientă a solului ca acumulator
                            constant de căldură pentru încălzire și încălzire apei menajere. Opțional sunt
                            disponibile și răcirea activă și pasivă, în funcție de model. Seria sol/apă
                            Ochsner absoarbe căldura din sol în trei moduri: prin colectoare orizontale
                            așezate plat în pământ, colectoare de șanț sau sonde geotermale instalate vertical.
                        </p>
                        <hr />
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>
                    <div class='col-lg-11 col-md-12 m-auto'>
                        <h3>Utilizarea optimă a energiei geotermale</h3>
                        <p>
                            Pompele de căldură cu sursă de sol utilizează temperatura uniformă a
                            solului ca sursă constantă și fiabilă de energie. Această energie poate
                            fi captată în două moduri, în funcție de condițiile de pe amplasament:
                            prin colectoare așezate orizontal în pământ sau prin sonde geotermale
                            scufundate vertical. În ambele cazuri, căldura este extrasă din sol și
                            nivelul temperaturii este ridicat prin intermediul procesului
                            termodinamic al pompei de căldură. Această energie este apoi disponibilă
                            pentru sistemul de încălzire și pentru încălzirea apei menajere.
                        </p>
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>
                <div class='col-lg-4 col-md-4 text-center mx-5 my-auto '>
                        <img
                            src={img3}
                            height="181"
                            width="260"
                            class="img-fluid"
                            alt="Sol-Apa"
                        />
                    </div>
                    <div class='col-lg-6 col-md-6 '>
                        <hr />
                        <h4>Energie geotermală gratuită</h4>
                        <p>
                            Procesul termodinamic al pompei de căldură folosește energia
                            geotermală gratuită pentru încălzire și încălzire apei menajere.
                        </p>
                        <hr />
                    </div>
                </div>

            </Container>
        );
    }
}

export default SolApa1;