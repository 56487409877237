import React, { Component } from 'react';
import GoToTop from '../Components/GoToTop';
import ApaMenajera1 from '../Components/ApaMenajera1';
import Bottom from '../Components/Bottom';

class ApaMenajera extends Component {
    render() {
        return (
            <>
                <ApaMenajera1 />
                <Bottom />
                <GoToTop />
            </>
        );
    }
}

export default ApaMenajera;