import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img from '../Components/img/principiu.gif'
import img2 from '../Components/img/energy_nature.webp'

class Home1 extends Component {
    render() {
        return (
            <Container fluid>
                <div class=' row mtext ptop mbg justify-content-md-center'>
                    <div class='col-lg-10 col-xxl-9 col-md-12'>
                        <h2 class='my-3 '> Ce este o pompă de căldură?</h2>
                        <p>- este un dispozitiv cu ajutorul căruia se poate transporta căldură de la o
                            locație (“sursă”) la o altă locație (“radiator” sau “schimbător de căldură”) folosind lucru
                            mecanic, de obicei în sens invers direcției naturale de mișcare a căldurii. Majoritatea pompelor
                            de căldură sunt folosite pentru a muta căldura de la o sursă cu temperatură mai mică la un radiator
                            cu temperatură mai mare.
                        </p>
                    </div>
                </div>
                <div class='row mbg justify-content-md-center'>
                    <div class='col-md-10 col-lg-9 text-center'>
                        <img
                            src={img}
                            height="619"
                            width="1100"
                            class="img-fluid"
                            alt="Principiu de functionare"
                        />
                    </div>
                </div>
                <div class='row mtext mbg justify-content-md-center pt-5'>
                    <div class='col-md-10'>
                        <h3 class='my-3 pbottom'> Pompe de căldură OCHSNER: calitate superioară fabricate în Austria</h3>
                    </div>
                </div>
                <div class='row mtext justify-content-md-center '>
                    <div class='col-lg-5 col-xl-5 col-md-5 col-sm-10 '>
                        <img
                            src={img2}
                            //  height="706" 
                            // width="500" 
                            class="par1"
                            alt="from nature"
                        />
                    </div>
                    <div class='col-lg-6 col-xl-5 col-xxl-4 col-md-7 col-sm-12 mbg  '>
                        <p>
                            Lider tehnologic în sectorul pompelor de căldură OCHSNER și-a construit o reputație ca lider
                            tehnologic în sectorul pompelor de căldură datorită realizărilor și inovațiilor sale tehnice remarcabile.
                            Compania își dezvoltă și fabrică produsele exclusiv în Germania și Austria, cu procese de producție care
                            respectă standardele Industrie 4.0
                        </p>
                        <p>
                            Din 1978 încoace, afacerea de familie OCHSNER a fost una dintre primele companii din lume care s-a
                            concentrat exclusiv pe dezvoltarea și producția de pompe de căldură de înaltă eficiență, de ultimă
                            generație.
                        </p>
                        <h3>Încălzire ecologică</h3>
                        <p>
                            Viziunea companiei este de a folosi energia de mediu pentru a contribui la rezolvarea provocărilor
                            viitorului nostru energetic global și a schimbărilor climatice. Scopul este de a sprijini în mod activ
                            eliminarea treptată a combustibililor fosili și de a face fiecare act de generare de căldură și fiecare
                            sistem de încălzire ecologic. De aceea sloganul nostru este: pompe de căldură OCHSNER – pentru mine și natură.
                        </p>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Home1;