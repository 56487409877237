import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img3 from '../Components/img/aer-apa.webp'
import img4 from '../Components/img/sol-apa.webp'
import img5 from '../Components/img/apa-apa.webp'
import img6 from '../Components/img/apa.webp'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

class Home2 extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mtext justify-content-center mbg pt-5'>
                    <div class='col-lg-10 col-md-10 col-sm-12  text-center'>
                        <h3>În funcție de sursa din care este transferată căldura: pământ, aer, apă sau sol, sunt tipuri diferite
                            de pompe de căldură: </h3>
                    </div>
                </div>
                <div class='row mtext justify-content-center mbg px-3'>
                    <div class='col-lg-3 col-md-8 text-center'>
                        <Nav class='justify-content-center m-3' >
                            <Nav.Link as={Link} to="/AerApa">

                                <img
                                    src={img3}
                                    class="img-fluid"
                                    alt="Aer-Apa"
                                />
                                <h5 className='mlink'>Pompe de tip Aer-Apă</h5>
                            </Nav.Link>
                        </Nav>
                    </div>
                    <div class='col-lg-3 col-md-6 text-center'>
                        <Nav class='justify-content-center m-3'>
                            <Nav.Link as={Link} to="/SolApa">
                                <img
                                    src={img4}
                                    class="img-fluid"
                                    alt="Sol-Apa"
                                />
                                <h5 className='mlink'>Pompe de tip Sol-Apă</h5>
                            </Nav.Link>
                        </Nav>
                    </div>
                    <div class='col-lg-3 col-md-6 text-center'>
                        <Nav class='justify-content-center m-3'>
                            <Nav.Link as={Link} to="/ApaApa">

                                <img
                                    src={img5}
                                    class="img-fluid"
                                    alt="Apa-Apa"
                                />
                                <h5 className='mlink'>Pompe de tip Apa-Apă</h5>
                            </Nav.Link>
                        </Nav>
                    </div>
                    <div class='col-lg-3 col-md-6 text-center'>
                        <Nav class='justify-content-center m-3'>
                            <Nav.Link as={Link} to="/ApaMenajera">
                                <img
                                    src={img6}
                                    class="img-fluid"
                                    alt="Apa Menajera"
                                />
                                <h5 className='mlink'>Pompe de pentru Apa Menajera</h5>
                            </Nav.Link>
                        </Nav>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Home2;