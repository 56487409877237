import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import Top from './Components/Top';
// import "@fontsource/poppins";
import "@fontsource/poppins/300.css"

function App() {
  return (
    <div>
      <Top />
    </div>
  );
}

export default App;
