import React, { Component } from 'react';
import SolApa1 from '../Components/SolApa1';
import SolApa2 from '../Components/SolApa2';
import Bottom from '../Components/Bottom';
import GoToTop from '../Components/GoToTop';


class SolApa extends Component {
    render() {
        return (
<>
<SolApa1 />
<SolApa2 />
<Bottom />
<GoToTop />
</>
        );
    }
}

export default SolApa;