import React, { Component } from 'react';
import Home1 from '../Components/Home1'
import Home2 from '../Components/Home2'
import Home3 from '../Components/Home3'
import Bottom from '../Components/Bottom'
import GoToTop from '../Components/GoToTop';

class Home extends Component {
    render() {
        return (
            <>
                
                <Home1 />
                {/* <hr Class='mline'/> */}
                <Home2 />
                <Home3 />
                <Bottom />
                <GoToTop />
            </>
        );
    }
}

export default Home;