import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img3 from '../Components/img/apa-apa3.webp';
import img4 from '../Components/img/apa-apa4.webp';

class ApaApa2 extends Component {
    render() {
        return (
            <Container>
                <div class='row mtext justify-content-center ptop'>
                    <div class='col-lg-4 col-md-4 text-center  my-auto '>
                        <img
                            src={img3}
                            height="270"
                            width="260"
                            class="img-fluid"
                            alt="device2"
                        />
                        <img
                            src={img4}
                            height="270"
                            width="260"
                            class="img-fluid"
                            alt="device3"
                        />
                    </div>
                    <div class='col-lg-7 col-md-8'>
                        <h3>Schimbător de căldură cu plăci sau carcasă și tub</h3>
                        <p>
                            Pompele de căldură pentru apă subterană OCHSNER utilizează două tipuri diferite de schimbătoare de
                            căldură: schimbătoare de căldură cu plăci sau înveliș și tub. Schimbătoarele de căldură cu plăci (PHE)
                            transferă căldura în mod eficient agentului frigorific și sunt potrivite dacă calitatea apei este
                            foarte bună. În timp ce schimbătoarele de căldură cu carcasă și tuburi (HE tubulare) au o capacitate
                            de transfer puțin mai mică, ele sunt capabile să facă față unei calități mai slabe a apei. Acest
                            lucru se datorează diverșilor factori. Grosimea peretelui conductei HE tubulare OCHSNER este dublă
                            față de schimbătoarele de căldură cu plăci. Oțelul inoxidabil 304 este utilizat la exterior și 1.4404
                            pentru conductele interioare. Acest lucru asigură o rezistență foarte bună la coroziune și scade riscul
                            de deteriorare mecanică. Diametrele interne ale canalelor tubulare HE OCHSNER pentru conducerea apei
                            subterane sunt de 250 de ori mai mari decât cele ale PHE. Prin urmare, nu sunt susceptibili la blocaje
                            cauzate de substanțele în suspensie din apă, cum ar fi nisipul și alți contaminanți. Acest lucru reduce,
                            de asemenea, riscul de îngheț. Pentru a asigura o fiabilitate de funcționare permanentă bună, un filtru
                            special este întotdeauna instalat în amonte.
                        </p>
                    </div>

                </div>
                <div class='row mtext justify-content-center pt-5 pbottom'>
                    <div class='col-lg-11 col-md-12 m-auto'>
                        <h3>Întrebări despre pompele de căldură de la apă</h3>
                        <p>
                            <b>Cum pot afla dacă apă subterană este disponibilă sub casa mea?</b>
                        </p>
                        <p>
                            Pentru a cântări opțiunile în primă instanță, este util să aflați dacă au fost deja instalate pompe de
                            căldură pentru apă subterană în vecinătate sau dacă s-au efectuat foraje de probă. Orice autoritate
                            regională pentru apă pot oferi informații despre disponibilitatea apei subterane și dacă aceasta poate
                            fi utilizată cu o pompă de căldură.
                        </p>
                        <p>
                            <b>Care sunt costurile de întreținere pentru o pompă de căldură subterană?</b>
                        </p>
                        <p>
                            Pompele de căldură pentru apă subterană OCHSNER sunt printre cele mai durabile și fiabile sisteme.
                            Doar dacă calitatea apei este slabă, filtrul din fântână de alimentare trebuie curățat la intervale de
                            timp sau schimbătorul de căldură trebuie spălat pentru a proteja împotriva colizării.
                        </p>
                        <p>
                            <b>Pompa mea de căldură subterană ar putea îngheța în ierni foarte reci?</b>
                        </p>
                        <p>
                            Apa subterană la adâncimile recomandate nu îngheață. Cu dimensionarea corectă, nu există niciun risc de îngheț.
                        </p>
                        <p>
                            <b>Sunt necesare permise sau înregistrare?</b>
                        </p>
                        <p>
                            Apele subterane sunt proprietate publică, supuse unei protecții speciale, și ne asigură alimentarea cu apă
                            potabilă. Prin urmare, permisele sunt întotdeauna obligatorii pentru utilizarea apelor subterane.
                        </p>
                    </div>
                </div>
            </Container>
        );
    }
}

export default ApaApa2;