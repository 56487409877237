import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img from '../Components/img/FE.webp';
import img2 from '../Components/img/aer-apa5.webp';
import img3 from '../Components/img/aer-apa6.webp';

class AerApa2 extends Component {
    render() {
        return (
            <Container>
                <div class='row mtext justify-content-center pt-5'>
                    <div class='col-lg-1 col-md-2 text-center mx-5 my-auto '>
                        <img
                            src={img}
                            height="44"
                            width="49"
                            class="img-fluid"
                            alt="F&E"
                        />
                    </div>
                    <div class='col-lg-6 col-md-7'>
                        <hr />
                        <h3>Cercetare și dezvoltare OCHSNER</h3>
                        <p>Pentru a rămâne la vârf tehnologic, OCHSNER investește aproximativ 6% din cifra de afaceri anuală în cercetare și dezvoltare.</p>
                        <hr />
                    </div>
                </div>
                <div class='row mtext justify-content-center pt-5'>

                    <h3>Performanță sonoră de la OCHSNER! Menținerea emisiilor de zgomot la minimum</h3>
                    <div class='col-lg-2 col-md-2 text-center my-auto mw-1'>
                        <img
                            src={img2}
                            height="69"
                            width="100"
                            class="img-fluid"
                            alt="sonor"
                        />
                    </div>
                    <div class='col-lg-10 col-md-10'>
                        <p>
                            Este imposibil să eliminați complet tot zgomotul pe care ventilatoarele îl fac atunci când iau aer.
                            Sistemele ieftine pot fi foarte zgomotoase, ceea ce vă poate enerva vecinii sau vă poate deranja
                            noaptea. De asemenea, trebuie respectate reglementările regionale de protecție împotriva zgomotului.
                            Evaporatoarele de banc OCHSNER cu ventilatoare instalate orizontal obțin în mod regulat cele mai bune
                            rezultate pentru emisiile de zgomot în teste independente. Sunt extrem de liniștiți. Pompele de căldură
                            cu sursă de aer premium din seriile OCHSNER Air Hawk și Air Eagle vă oferă beneficiile suplimentare ale
                            tehnologiei inverter. Puterea compresorului, precum și viteza ventilatoarelor pot fi ajustate variabil
                            la cererea curentă de căldură. Acest lucru le face semnificativ mai silențioase și mai eficiente.
                            Ieșirea poate fi redusă și mai mult la anumite momente, cu un mod special de silențios. Sunt
                            disponibile și panouri de atenuare a sunetului pentru secțiunile exterioare.
                        </p>
                    </div>
                </div>
                <div class='row mtext justify-content-center pbottom pt-5'>
                    <div class='col-lg-7 col-md-8'>
                        <hr />
                        <h3>Beneficii de instalare OCHSNER! Rapid de instalat, fiabil de operat</h3>
                        <p>
                            Pentru a instala o pompă de căldură cu sursă de aer OCHSNER, nu este necesar să se facă găuri sau să
                            se pună colectoare. Acest lucru face instalarea mai rapidă și mai ușoară decât cea a sistemelor de
                            sursă subterană sau de apă subterană. Unitățile interioare care economisesc spațiu sunt abia mai mari
                            decât un frigider și pot fi instalate fără efort în subsol, în camera de serviciu sau pe hol. Unitățile
                            exterioare sunt montate în grădină sau pe un acoperiș de garaj sau un acoperiș plat. Conexiunile gata
                            de montat facilitează conectarea sistemului la instalațiile existente de încălzire și apă caldă menajeră.
                            Serviciul cuprinzător pentru clienți OCHSNER vă ajută cu punerea în funcțiune, service și întreținere.
                        </p>
                        <hr />
                    </div>
                    <div class='col-lg-4 col-md-4 text-center  my-auto '>
                        <img
                            src={img3}
                            height="506"
                            width="217"
                            class="img-fluid"
                            alt="device"
                        />
                    </div>
                </div>

            </Container>
        );
    }
}

export default AerApa2;