import React, { Component } from 'react';

class MapGoogle extends Component {
    render() {
        return (
            <>
                <div><iframe title='map' width="100%" height="330" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1359.9370184978584!2d28.791682271250682!3d47.02307748466557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d000a674291%3A0xc041ca08c4b89e75!2sGeotermal-AV%20SRL!5e0!3m2!1sru!2s!4v1704480506906!5m2!1sru!2s&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
            </>
        );
    }
}

export default MapGoogle;