import React, { Component } from 'react';
import GoToTop from '../Components/GoToTop';
import ApaApa1 from '../Components/ApaApa1';
import ApaApa2 from '../Components/ApaApa2';
import Bottom from '../Components/Bottom';

class ApaApa extends Component {
    render() {
        return (
            <>
                <ApaApa1 />
                <ApaApa2 />
                <Bottom />
                <GoToTop />
            </>
        );
    }
}

export default ApaApa;